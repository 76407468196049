.shop_data {
	> a {
		display: block;
		width: 100%;
		text-align: center;
		padding: 12px 35px;
		background-image: url("/assets/smp/images/base/base_btn_ic_pitat.webp"),
		url("/assets/smp/images/base/base_ic_link.webp");
		background-position: 10px 50%, right;
		background-size: 25px auto, 18px 13px;
		background-repeat: no-repeat;
		@include funcBoxSizingBorderBox();
		font-size: 15px;
		background-color: #fffee4;
		line-height: 1.2;
		font-weight: bold;
		color: #39c;
	}
}

.mlRental {
	border-bottom: 1px solid #ddd;
	position: sticky;
	top: 0;
	z-index: 1;

	> .name {
		text-align: center;
		background-image: url("/assets/smp/images/header/bg_rental.webp");
		background-repeat: repeat-x;
		background-position: left bottom;
		overflow: hidden;

		> a {
			font-weight: bold;
			font-size: 18px;
			display: block;
			line-height: 1.2;
			padding: 12px 10px;
			background-image: url("/assets/smp/images/base/base_ic_link.webp");
			background-repeat: no-repeat;
			background-size: 18px 13px;
			background-position: right;

			> span {
				margin-top: 4px;
				display: block;
				font-size: 14px;
			}
		}
	}

	> .tel {
		text-align: center;

		> a {
			display: block;
			background-color: #fffee4;
			padding: 10px;
			color: #39c;
			font-size: 14px;
			line-height: 1.0;
		}
	}
}
