@mixin mlTitleTopImg(){
		> h2 {
		@include funcPaddingVerHori(10, 10);
		margin: 0px 0px 2px;
	}
}

@mixin mgImageMaxWide() {
	@include funcMaxWidth($dgBoxWidthMaxWide);
	@include funcMarginAuto();

	img {
		@include funcWidthPcnt(100);
	}
}


@mixin mgHover($color) {
	-webkit-tap-highlight-color: $color;
	tap-highlight-color: $color;
}



@mixin mgRoundCorner($size, $weight, $style, $color)
{
	@include funcBorderRadius($size);
	@include funcBorder($weight, $style, $color);
}

@mixin mgRoundCornerBottom($size, $weight, $style, $color)
{
	@include funcBorderRadiusBottom($size);
	@include funcBorder($weight, $style, $color);
}

@mixin mgRoundCornerTop($size, $weight, $style, $color)
{
	@include funcBorderRadiusTop($size);
	@include funcBorder($weight, $style, $color);
}

h1 {
	@include funcBkColor(#c9c9c9);
	@include funcLineHeight(26);
	@include funcFont(13);
	@include funcFontColor($dgFontColorHalf);
	@include funcPaddingLeft(10);
	@include funcPaddingRight(10);

}

h2 {
	@include funcBkColor($themeColor);
	@include funcPaddingVerHori(15, 10);
	@include funcFontColor(#fff);
	@include funcFont($dgFontSizeBase + 3);
	@include funcFontWeight(bold);
}

@mixin mgInputFontSize($size)
{

	> input[type="text"] {
		@include funcFont($size);
	}
}

@mixin mgIconPositionClassImg($element, $sizeW, $posT, $posL, $sizePdgL) {

	> #{$element} {
		background-size: $sizeW auto;
		background-position: $posT $posL;
		@include funcPaddingLeft($sizePdgL);
	}
}


@mixin mgImgLink() {

	> a {
		@include funcBkColor($dgBoxColorBase);
	}

	> a:hover {
		@include funcOpacity(0.7);
	}
}


@mixin mgShadowBox($sizeBdr, $colorBdr, $colorSdw) {
	@include mgRoundCorner($dgBoxRadiusBase, $sizeBdr, solid, $colorBdr);
	@include funcBoxShadowBottom($dgShadowSizeBase, $colorSdw);
}


@mixin mgTitleLeftIcon($element, $img) {

	> #{$element} {
		@include funcBkColor($dgColorPitat);
		@include funcPadding(8, $dgBoxMarginBase, 8, 40);
		@include funcFontColor($dgFontColorInvert);
		@include funcFontWeight(bold);
		@include funcFont($dgFontSizeBase + 3);
		@include funcBackgroundImg($img);
		@include funcBackgroundPositionXY(10px, 0);
		@include funcBackgroundNoRepeat();
		@include funcBkSizeAutoHeight(35);
	}
}


@mixin mgSelectBox() {
	@include funcPadding(8, 30, 5, 8);
	@include funcAppearance(none);
	@include funcBorder($dgBorderWidthBase, solid, $dgBorderColorBase);
	@include funcBorderRadius(5);
	@include funcBoxShadowBottom($dgShadowSizeBase, $dgShadowColorBase);
	@include funcBackgroundImg(url("/assets/smp/images/base/base_ic_select.webp"));
	@include funcBackgroundPositionXY(100%, 50%);
	@include funcBackgroundNoRepeat();
	@include funcBkSizeWidthAuto(17);
	@include funcBkColor($dgBoxColorBase);
	@include funcFont($dgFontSizeBase);
}


@mixin mgCheckBox($pos_vertical) {

	> input[type="checkbox"] {
		background: none;
		padding: 0;
		margin: 0;
		border: none;
		outline: none;
		-webkit-appearance: none;
		appearance: none;
		width: 22px;
		height: 22px;
		background-image: url("/assets/smp/images/base/base_ic_checkbox.webp");
		background-position: 0px 0px;
		background-repeat: no-repeat;
		background-size: 22px;
		@include funcVerticalAlign($pos_vertical);
	}

	> input[type="checkbox"]:checked {
		background-image: url("/assets/smp/images/base/base_ic_checkbox_on.webp");
	}

	> input[type="checkbox"]:disabled {
		background-image: url("/assets/smp/images/base/base_ic_checkbox_disabled.webp");
	}
}

@mixin mgRadio($pos_vertical) {

	> input[type="radio"] {
		margin: 0;
		padding: 0;
		outline: none;
		-webkit-appearance: none;
		appearance: none;
		border: none;
		width: 22px;
		height: 22px;
		background: none;
		background-image: url("/assets/smp/images/base/base_ic_radio.webp");
		background-position: left;
		background-repeat: no-repeat;
		background-size: 22px;
		vertical-align: $pos_vertical;
	}

	> input[type="radio"]:checked {
		background-image: url("/assets/smp/images/base/base_ic_radio_on.webp");
	}
}



@mixin mgTitleWithBackBtn($size) {
	@include funcBorderBottom($dgBorderWidthBase, solid, $dgBorderColorBase);
	@include funcPosition(relative);

	> div {
		@include funcDisplay(inline-block);
		@include funcPadding(14, 0, 14, 30);
		@include funcBackgroundImg(url("/assets/smp/images/base/base_ic_search.webp"));
		@include funcBackgroundPositionXY(10px, 17px);
		@include funcBackgroundNoRepeat();
		@include funcBkSizeWidthAuto(14);
		@include funcFontWeight(bold);
		@include funcFont($dgFontSizeBase + 1);
		@include funcWidthPcnt($size);

		> span {
			@include funcFontWeight(normal);
			@include funcFont($dgFontSizeBase - 1);
		}
	}

	> .clear_btn {
		@include funcTextAlign(center);
		@include funcDisplay(block);
		float: right;
		padding: 6px 12px 6px 24px;
		background-color: #fff;
		background-image: url("/assets/smp/images/base/base_ic_back.webp");
		background-position: 8px 50%;
		background-repeat: no-repeat;
		background-size: 8px 15px;
		border: 2px solid #bbb;
		border-radius: 5px;
		font-size: 12px;
		font-weight: normal;
		position: absolute;
		top: 6px;
		right: 10px;
	}

	> a {
		@include mgHover($dgLinkHoverBkColorBase);
	}
}



@mixin mgLinkList($element, $element2, $colorBdr, $colorHvr) {
	> #{$element} {
		@include funcOverflowHidden();

		> #{$element2} {
			@include funcFloat(left);
			@include funcWidthPcnt($dgBoxWidthWide);

			> a {
				@include funcBorderTop($dgBorderWidthBase, solid, $colorBdr);
				@include funcBorderLeft($dgBorderWidthBase, solid, $colorBdr);
				background-image: url("/assets/smp/images/base/base_ic_link.webp");
				background-position: right;
				background-repeat: no-repeat;
				background-size: 18px 13px;
			}
		}

		> #{$element2}.col2 {
			@include funcWidthPcnt(($dgBoxWidthWide / 2));
		}

		> #{$element2}:first-child {
			> a {
			@include funcBorderTopNone();
			@include mgHover($colorHvr);
			}
		}
	}
}


@mixin mgIconLinkList($element, $element2, $colorBdr, $colorHvr, $sizeW, $posT, $posL, $sizePdgL) {
	@include mgLinkList($element, $element2, $colorBdr, $colorHvr);
	@include mgIconPositionClassImg(a, $sizeW, $posT, $posL, $sizePdgL);
}


@mixin mgRadiusContentsLink($element, $element2, $colorBdr, $colorHvr, $colorSdw) {
	@include mgIconLinkList($element, $element2, $colorBdr, $colorHvr, 40, 8, 8, 40 + 6 + 20);
	@include funcWidthPcnt($dgBoxWidthWide);
	@include funcPaddingBottom($dgBoxContentsSeparator);
	@include funcDisplay(block);

	> #{$element} {
		@include mgShadowBox($dgBorderWidthBase, $colorBdr, $colorSdw);
		@include funcMarginTopSideBtm(0, $dgBoxMarginBase, 0);
		@include funcBorderLeftNone();
		@include funcBkColor($dgBoxColorBase);

		> #{$element2} {
			background-position: 8px 50%;
			background-repeat: no-repeat;
			background-size: 40px;

			> a {
				background-image: url("/assets/smp/images/base/base_ic_link.webp");
				background-position: right;
				background-repeat: no-repeat;
				background-size: 18px 13px;
				@include funcPadding(16, 20, 16, 60);
				@include funcFont($dgFontSizeBase + 3);
				@include funcDisplay(block);
			}
		}
	}
}


@mixin mgSelectSearchLink(){
	background-color:#eee;
	padding: 0 0 10px 10px;
	overflow: hidden;

	> ul {
		width: 100%;
		@include funcBkColor(#ddd);

		> li {
			float: left;
			text-align: center;
			margin-top: 10px;

			> a {
				display: block;
				height: 100%;
				border: 1px solid #ddd;
				border-radius: 5px;
				margin-right: 10px;
				@include funcBoxShadowBottom(2, $dgShadowColorBase);
				background-image: url("/assets/smp/images/base/base_ic_link.webp");
				background-position: 8px 50%;
				background-repeat: no-repeat;
				background-size: 18px 13px;
				background-color: #fff;
				@include mgHover($themeHoverColor);

				> img {
					height: 100%;
				}
			}
		}

		> .col2 {
			@include funcWidthPcnt(50);
			height: 110px;
		}

		> .col3 {
			@include funcWidthPcnt((100 / 3));
			height: 105px;
		}
	}
}

@mixin mgSubTop(){
	> section {

		> section {

			> .select_follow {
				@include mgTitleWithBackBtn(70);
			}

			> .select_search_link {
				@include mgSelectSearchLink();
			}
		}
	}

	> .request_link {
		margin: 20px 10px;

		> form {
			max-width: 420px;
			margin: 0 auto;
			text-align: center;

			> input[type="submit"] {
				margin: 0 auto;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				width: 100%;
				height: 55px;
				padding: 0 10px;
				display: block;
				box-shadow: 0 2px 0 0 $requestBoxShadowColor;
				border: 1px solid $requestBorderColor;
				border-radius: 5px;
				background-position: 10px 50%, 60% 50%, 98% 50%;
				background-repeat: no-repeat;
				background-size: 44px auto, auto 43px, 20px auto;
				background-color: $themeColor;
				text-align: center;
			}

			> .sale_req {
				background-image: url("/assets/smp/images/base/base_btn_ic_request.webp"),
				url("/assets/smp/images/sale/sale_btn_request.webp"),
				url("/assets/smp/images/base/base_ic_arrow_right.webp");
			}

			> .rent_req {
				background-image: url("/assets/smp/images/base/base_btn_ic_request.webp"),
				url("/assets/smp/images/rent/rent_btn_request.webp"),
				url("/assets/smp/images/base/base_ic_arrow_right.webp");
			}
		}
	}

	> section.pickup_link {
		@include mgTitleLeftIcon(h3, url("/assets/smp/images/base/base_title_ic_pickup.webp"));
		@include funcOverflowHidden();
		@include funcMarginBottom($dgBoxContentsSeparator);
		> ul {
			@include funcMarginRight($dgBoxMarginBase);
			> li {
				@include funcWidthPcnt(($dgBoxWidthWide / 2));
				@include funcFloat(left);
				@include mgImgLink();
				> a {
					@include funcDisplay(block);
					@include funcMargin($dgBoxMarginBase, 0, 0, $dgBoxMarginBase);
				}
				> p {
					@include funcFont(11);
					@include funcLineHeight(15);
					@include funcMargin($dgBoxMarginBase - 5, 0, 0, $dgBoxMarginBase);
				}
			}
		}
	}
}

section.feature_link {
    @include mgTitleLeftIcon(h3, url("/assets/smp/images/base/base_title_ic_feature.webp"));
    @include funcOverflowHidden();
    @include funcMarginBottom($dgBoxContentsSeparator);

    > ul {
        @include funcMarginRight($dgBoxMarginBase);
        > li {
            @include funcWidthPcnt(($dgBoxWidthWide / 2));
            @include funcFloat(left);
            @include mgImgLink();
            > a {
                @include funcDisplay(block);
                @include funcMargin($dgBoxMarginBase, 0, 0, $dgBoxMarginBase);
            }
        }
    }
}

section.salerent_link {
    margin-bottom: 20px;
    @include mgTitleLeftIcon(h3, url("/assets/smp/images/base/base_title_ic_salerant.webp"));

    > ul {
        margin: 10px 10px 0;
        @include funcBkColor($dgBorderColorBase);
        @include funcBorderRight(1, solid, $dgBorderColorBase);
        @include funcBorderBottom(1, solid, $dgBorderColorBase);
        border-radius: 5px;
        overflow: hidden;
        @include funcBoxShadowBottom(2, $dgShadowColorBase);

        > li {
            width: 100%;

                > a {
                    padding: 0;
                    border-width: 0;
                    -webkit-appearance: none;
                    appearance: none;
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding: 15px 25px 15px 50px;
                    @include funcBorderTop(1, solid, $dgBorderColorBase);
                    @include funcBorderLeft(1, solid, $dgBorderColorBase);
                    font-size: 16px;
                    background-position: right, 8px 50%;
                    background-size: 18px 13px, 32px auto;
                    background-repeat: no-repeat;
                    background-color: #fff;
                    @include mgHover($dgLinkHoverBkColorBase);
                    @include funcBoxSizingBorderBox();
                }

                > .salerent01-icon {
                    background-image:
                        url("/assets/smp/images/base/base_ic_link.webp"),
                        url("/assets/smp/images/base/base_ic_salerent01.webp");
                }

                > .salerent02-icon {
                    background-image:
                        url("/assets/smp/images/base/base_ic_link.webp"),
                        url("/assets/smp/images/base/base_ic_salerent02.webp");
                }

        }

        .link_list li.col2 {
            @include funcWidthPcnt(50);
        }
    }
}

section.todofuken_text {
    @include mgTitleLeftIcon(h3, url("/assets/smp/images/base/base_title_ic_todo_fuken.webp"));
    @include funcOverflowHidden();
    @include funcMarginBottom($dgBoxContentsSeparator);
    > div {
    	letter-spacing: 0.2em;
		margin: 8px 13px;
    	padding-top: 5px;
    }
}

@mixin mgSellOwnerTop(){
	> .mlRentSell {
		> h2 {
			font-weight: bold;
			color: #fff;
			font-size: 16px;
			padding: 15px 10px;
			background-color: $themeColor;
		}

		> .main_visual {
			max-width: 400px;
			margin: 0 auto 20px;

			> img{
				width: 100%;
				height: auto;
			}
		}

		> .btn_counceling {
			padding: 0 10px;
			margin-bottom: 20px;

			> form {

				> input[type="submit"] {
					padding: 0;
					border-width: 0;
					-webkit-appearance: none;
					appearance: none;
					margin: 0 auto;
					width: 100%;
					height: 50px;
					text-align: center;
					display: block;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					background-color: #65b820;
					box-shadow: 0 2px 0 0 #398000;
					background-size: auto 56px;
					background-position: 50% 50%;
					background-repeat: no-repeat;
					border-radius: 5px;
					@include mgHover(#ff8a00);
				}

				> .sell_req {
					background-image: url("/assets/smp/images/sell/sell_btn_counseling.webp");
				}

				> .rentowner_req {
					background-image: url("/assets/smp/images/rentowner/rentowner_btn_counseling.webp");
				}
			}
		}
	}

	> .mlContentsLink {
		overflow: hidden;
		margin-bottom: 20px;

		> div {
			background-color: #65b820;
			padding: 8px 10px 8px 40px;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			background-image: url("/assets/smp/images/base/base_title_ic_contents.webp");
			background-position: 10px 0px;
			background-repeat: no-repeat;
			background-size: auto 35px;
			-webkit-background-size: auto 35px;
		}

		> ul {
			margin-right: 10px;

			> li {
				width: 50%;
				float: left;

				> a {
					background-color: #fff;
					display: block;
					margin: 10px 0px 0px 10px;

					> img{
						width: 100%;
						height: auto;
					}
				}

				> a:hover {
					opacity: 0.7;
				}
			}
		}
	}


	@media screen and (min-width: 560px) {

		>.mlContentsLink {
			> ul {
				> li {
					width: 25%;
				}
			}
		}
	}
}

@mixin mgFlow(){

	> h2 {
		font-weight: bold;
		color: #fff;
		font-size: 16px;
		padding: 15px 10px;
		background-color: $themeColor;
	}

	> .steps{
		margin-bottom: 30px;

		> h3{
			padding: 10px;
			font-weight: bold;
			font-size: 14px;
			display: table;
			border-bottom: 2px solid #bbb;
			background-image: url(/assets/smp/images/base/base_bg_step.webp);
			background-position: 10px 50%;
			background-repeat: no-repeat;
			background-size: auto 26px;
			width: 100%;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			margin-bottom: 15px;

			> span {
				display: table-cell;
				color: #fff;
				width: 75px;
				padding: 4px 20px 4px 10px;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				font-size: 13px;
				vertical-align: middle;
			}

			> div{
				display: table-cell;
				vertical-align: middle;
				padding: 4px 0;
			}
		}

		> .flow_detail{

			> .flow_ilst{
				width: 240px;
				margin: 0 auto 15px;

				> img{
					width: 100%;
					height: auto;
				}
			}

			> .flow_txt {
				font-size: 13px;
				margin: 0 10px 15px;

				> h4 {
					font-weight: bold;
					color: #65b820;
					margin-top: 10px;
				}

				> ul{
					margin-top: 10px;

					> li {
						background-image: url(/assets/smp/images/base/base_ic_list_dice.webp);
						background-position: 0px 5px;
						background-repeat: no-repeat;
						background-size: 6px auto;
						font-weight: bold;
						padding-left: 15px;
						margin-bottom: 8px;
					}
				}

				> div{
					margin: 15px auto 0;
					max-width: 330px;

					> img{
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}

	> .steps{

		> .link {
			margin: 0 10px;
			border: 1px solid #ddd;
			-webkit-box-shadow: 0 2px 0 0 #bbb;
			-moz-box-shadow: 0 2px 0 0 #bbb;
			box-shadow: 0 2px 0 0 #bbb;
			border-radius: 5px;

			> li{
				background-image: url(/assets/smp/images/base/base_ic_link.webp);
				background-position: right;
				background-repeat: no-repeat;
				background-size: 18px 13px;
				border-bottom: 1px solid #ddd;
				@include mgHover(#dfffc3);

				> a {
					display: block;
					padding: 15px 30px 15px 50px;
					color: #39c;
					font-weight: bold;
					background-position: 10px 50%;
					background-repeat: no-repeat;
					background-size: 28px auto;
					line-height: 1.2;
				}

				> form {
					> input[type="submit"] {
						padding: 0;
						border-width: 0;
						-webkit-appearance: none;
						appearance: none;
						width: 100%;
						display: block;
						padding: 15px 30px 15px 50px;
						color: #39c;
						font-weight: bold;
						background-position: 10px 50%, right;
						background-repeat: no-repeat;
						background-size: 28px auto, 18px 13px;
						background-color: #fff;
						background-image: url("/assets/smp/images/base/base_btn_ic_house_mail.webp"),
						url("/assets/smp/images/base/base_ic_link.webp");
						text-align: left;
						font-size: 13px;
						@include funcBoxSizingBorderBox();
						line-height: 1.2;
					}
				}

				> .ic_pitat {
					background-image: url("/assets/smp/images/base/base_btn_ic_pitat.webp");
				}

				> .ic_money {
					background-image: url("/assets/smp/images/base/base_btn_ic_yen.webp");
				}

				> .ic_point {
					background-image: url("/assets/smp/images/base/base_btn_ic_house_check.webp");
				}
				> .ic_pen {
					background-image: url("/assets/smp/images/base/base_btn_ic_pen.webp");
				}

				> .ic_counceling {
					background-image: url("/assets/smp/images/base/base_btn_ic_building_tag.webp");
				}

			}

			> li:last-child {
				border-bottom: none;
			}
		}

		> .flow_graph {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			max-width: 420px;
			padding: 10px 10px 0;
			text-align: center;
			margin: 0 auto;

			> img {
				width: 100%;
				height: auto;
			}
		}
	}


		@media screen and (min-width: 560px) {


		> .steps {
			> .flow_detail {
				> .flow_ilst {
					float: left;
					margin-left: 10px;
				}

				> .flow_txt {
					margin-left: 270px;
					min-height: 180px;
				}
			}
		}


		}

}

.select_follow {
	@include mgTitleWithBackBtn(70);
}







.ui-widget-overlay{
	background-color: rgba(0,0,0,0.8);



}

.ui-dialog {
	position: relative;
	width: 100% !important;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	.ui-dialog-content {
	padding: 20px 10px;

		> .popup {
			width: 100%;
			background-color: #fff;
			position: relative;
			overflow: hidden;

			> .wrapper {
				height: 28px;
				width: 100%;
				margin-bottom: 12px;

				> .close {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					padding: 0;
					border: 0;
					outline: 0;
					background-image: url(/assets/smp/images/base/base_btn_close_w.webp);
					background-repeat: no-repeat;
					background-size: 30px;
					background-color: #fff;
					display: block;
					position: absolute;
					width: 30px;
					height: 30px;
					top: 4px;
					right: 4px;
				}

				> button {

					> span {
						display: none;
					}
				}

				> .pager {
					margin-left: 10px;
					padding: 10px 0;
					overflow: hidden;

					> li {
						float: left;
						margin-right: 8px;
						font-size: 12px;
					}
				}
			}

			> .main_visual {
				margin: 0 0 10px 10px;
				overflow: hidden;

				> ul {
					overflow: hidden;

					> li {
						float: left;
						margin-right: 8px;

						> img {
							width: 300px;
							margin-bottom: 4px;
							background-color: #eee;
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
							box-sizing: border-box;
							height: auto;
						}

						> .text_area {
							text-align: center;
							width: 300px;
						}
					}
				}
			}

			> .thumbnail {
				margin: 0 0 10px 10px;
				overflow: hidden;

				> ul {
					overflow: hidden;

					> li {
						float: left;
						margin-right: 8px;

						> img {
							border: 1px solid #ddd;
							background-color: #eee;
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
							box-sizing: border-box;
							height: auto;
						}
					}
				}
			}

			> .map {
				margin: 0 10px 10px;

				> div {
					width: 100%;
					height: 300px;
					border: 1px solid #ddd;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					margin-bottom: 4px;
				}

				> p {
					line-height: 1.4;
				}
			}

			> .title {

				> h3 {
					font-weight: bold;
					background-image: url("/assets/smp/images/base/base_ic_map_g.webp");
					background-position: left;
					background-repeat: no-repeat;
					background-size: auto 16px;
					font-size: 14px;
					padding-left: 15px;
					margin-bottom: 4px;
					position: absolute;
					left: 10px;
					top: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 355px) {
	.ui-dialog {
		> .ui-dialog-content {
			> .popup {
				> .main_visual {
					> ul {
						> li {
							> img {
								width: 260px;
							}

							> .text_area {
								width: 260px;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}


.tmpFeatureImg {
	@include funcBkColor(#c9cac6);

	> div {
		max-width: 420px;
		margin: 0 auto;
		@include funcTextAlign(center);

		> img {
			width: 100%;
			height: auto;
			@include funcVerticalAlign(middle);
		}
	}
}

.mlSoudanTool{
	position: fixed;
	bottom: 0;
	width: 100%;

	> form{
		background-color: rgba(127,127,127,0.8);
		padding: 10px 0 10px 10px;

		> ul {
			width: 100%;
			display: table;

			> li {
				display: table-cell;
				text-align: center;
				padding-right: 10px;

				 > input[type="submit"].inquiry {
					padding: 0;
					border-width: 0;
					-webkit-appearance: none;
					appearance: none;
					width: 100%;
					height: 45px;
					background-image:url("/assets/smp/images/sell/btn_satei.webp");
					background-size: auto 14px;
					background-repeat: no-repeat;
					background-position: 50% 50%;
					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
					background-color: #ffa800;
					@include funcBorderRadius(5);
					@include mgHover(#ff8a00);
				}
			}
		}
	}
}

.mlRequestBnr{
	margin: 10px;
	>div{
		> a {
			> img {
				width: 100%;
			}
		}
	}
}

.itemlink_data {

	.itemlink_title {
		@include mgTitleLeftIcon(h2, url("/assets/smp/images/base/base_title_ic_contents.webp"));
		@include mgRadiusContentsLink(div, div, $dgBorderColorBase, $dgLinkHoverBkColorBase, $dgShadowColorBase);
		@include funcPaddingBottom( 0);
	}


	.itemlink_box {

		> h2 {
			border-bottom: 1px solid #ddd;
			background-color: #65b820;
			padding: 8px 10px 8px 40px;
			color: #fff;

			> span {
				font-weight: bold;
				font-size: 15px;
			}

			> img {
				width: 35px;
			    height: 35px;
			    vertical-align: middle;
			    float: right;
			}
		}

		> .on {
			background-image: url("/assets/smp/images/base/base_title_ic_contents.webp"),url("/assets/smp/images/base/base_btn_ic_minus_circle.webp");
			background-position: 10px 0 , right 10px center;
			background-size: auto 35px , 16px 16px;
			background-repeat: no-repeat;
			padding: 8px 10px 8px 40px;
//			margin-right: 10px;

		}

		> .off {
			background-image: url("/assets/smp/images/base/base_title_ic_contents.webp"),url("/assets/smp/images/base/base_btn_ic_plus_circle.webp");
			background-position: 10px 0 , right 10px center;
			background-size: auto 35px , 16px 16px;
			background-repeat: no-repeat;
			padding: 8px 10px 8px 40px;
			border-bottom: 1px solid #ddd;
//			margin-right: 10px;
		}

		> ul {
			display: none;

			> li {
				border-bottom: 1px solid #ddd;

				> a {
					line-height: 1.2;
					padding: 12px 30px 12px 20px;
					display: block;
					background-color:#fff;
					background-image:url("/assets/smp/images/base/base_ic_link.webp") ;
					background-position: right;
					background-repeat: no-repeat;
					background-size: 18px 13px;
				}

				> div {
					border-bottom: 1px solid #ddd;
					padding: 5px 30px 5px 10px;
				}

				> ul {

					> li {
						border-bottom: 1px solid #ddd;

						> span {
							line-height: 1.2;
							padding: 5px 30px 5px 20px;
							display: block;
							background-color:#fff;
							background-position: right;
							background-repeat: no-repeat;
							background-size: 18px 13px;
						}

						> a {
							line-height: 1.2;
							padding: 5px 30px 5px 20px;
							display: block;
							background-color:#fff;
							background-image:url("/assets/smp/images/base/base_ic_link.webp") ;
							background-position: right;
							background-repeat: no-repeat;
							background-size: 18px 13px;
						}
					}
				}

			}
		}

		> .area_info {
			padding: 10px;
		}

	}

	.itemlink_bukken_box {

		> ul {
			> li {
				border-bottom: 1px solid #ddd;
				background-color:#fff;
				background-image:url("/assets/smp/images/base/base_ic_link.webp") ;
				background-position: right;
				background-repeat: no-repeat;
				background-size: 18px 13px;
				border-bottom: 1px solid #ddd;

				> a {
					padding: 5px 10px;
					display: block;

					> span {
						font-weight: bold;
					}

					> .bukken_Info1{
						padding: 5px 15px;
					}

					> .bukken_Info2{
						padding: 5px 15px;
					}
				}
			}
		}

		> .area_info {
			padding: 10px;
		}

	}

}

.tmpSearchNav {
    .title {
        background-image: url("/assets/smp/images/base/base_title_ic_pickup.webp");
    }

    .jsSearchKind {
        margin: 15px;
    }
}

.loan_caution{
	@include funcBorder(1,solid,#ff0000);
	@include funcLineHeight(20);
	@include funcPaddingAll(10);
	@include funcFontColor(#ff0000);
	@include funcMarginBottom(10);
	@include funcMarginTop(10);
}


.link_container {
    @include funcDisplay(table);
    @include funcWidthPcnt(100);
    table-layout: fixed;
    div.row {
        @include funcDisplay(table-row);
        div.link {
            @include funcHeight(35);
            @include funcDisplay(table-cell);
            @include funcVerticalAlign(middle);
            @include funcBorder(1,solid,#ddd);
            > a {
                @include funcFontColor(#0c20f7);
                @include funcPaddingLeft(10);
                @include funcDisplay(block);
				width: 100%;
                background-image: url("/assets/smp/images/base/base_ic_jumper.webp");
                background-repeat: no-repeat;
                background-position: 90%;
                background-size: 15px;
            }

        }
    }
}

.soba_text {
    @include funcPaddingLeft(10);
    @include funcPaddingRight(10);
}

.mlSobaTable {

	> div {
		@include funcPaddingTop( 10);
		@include funcLineHeight(18);
	}
	> div.link_container {
		@include funcPaddingBottom( 10);
	}
	> table {
		@include funcBorderTop( 2, dotted, #f5e6c9);
		@include funcBorderRight( 2, dotted, #f5e6c9);
        @include funcWidthPcnt(100);

		> tbody {

			> tr {
				> th {
					@include funcHeight(40);
					@include funcWidth(190);
					@include funcFontWeight(bold);
					@include funcVerticalAlign(middle);
					@include funcTextAlign(center);
					@include funcBkColor(#eee);
					@include funcBorderLeft( 2, dotted, #f5e6c9);
					@include funcBorderBottom( 2, dotted, #f5e6c9);
				}

				> td {
					@include funcHeight(40);
					@include funcWidth(190);
					@include funcVerticalAlign(middle);
					@include funcTextAlign(center);
					@include funcBorderLeft( 2, dotted, #f5e6c9);
					@include funcBorderBottom( 2, dotted, #ede8de);

                    > a {
                        @include funcFontColor(#0c20f7)
                    }
				}

				> td.min {
					@include funcBkColor(#ccefff);
				}

				> td.max {
					@include funcBkColor(#ffe5e9);
				}

				> th.title {
					@include funcHeight(40);
					@include funcBkColor(#f5e6c9);
					@include funcTextAlign(left);
					@include funcPaddingLeft( 10);
					@include funcFont(14);
					@include funcLineHeight(20);
					@include funcBorderBottom( 2, dotted, #ffffff);
				}

				> td.title {
					@include funcBkColor(#ede8de);
					@include funcTextAlign(left);
					@include funcPaddingLeft( 10);
					@include funcFont(14);
					@include funcBorderBottom( 2, dotted, #ffffff);
				}

			}
		}
	}
}

.soba_search {
    margin: 5px;

    > ul {
        display: flex;

        > li {
            margin: 5px;

            > label {

                > input[type="radio"] {
                    display: table-cell;
                    padding: 0;
                    margin: 0;
                    appearance: none;
                    width: 22px;
                    height: 22px;
                    background-image: url("/assets/smp/images/base/base_ic_radio.webp");
                    background-position: left;
                    background-repeat: no-repeat;
                    background-size: 22px;
                    vertical-align: middle;
                }

                > input[type="radio"]:checked {
                    background-image: url("/assets/smp/images/base/base_ic_radio_on.webp");
                }

                > span {
                    display: table-cell;
                    vertical-align: middle;
                    font-size: 14px;
                    padding-left: 4px;
                }
            }
        }
    }
}

@mixin mgBlockSpace {
	@include funcMarginBottom( $dgBoxMarginBase);
}

@mixin mgVerticalImage()
{
	> img {
		@include funcVerticalAlign(middle);
	}
}

@mixin mgContentBorder()
{
	@include funcBorder($dgBorderWidthBase, solid, $dgBorderColorBase);
}

@mixin mgPitatLineHeight()
{
	@include funcLineHeight($dgFontLineheightBase);
}

@mixin mgContentCircleBox( $size, $theme, $top_posi)
{
	@include mgBlockSpace();
	@include funcPaddingTop( $size);
	@include mgRoundCorner($dgBoxRadiusBase, $dgBorderWidthBase, solid, $dgBorderColorBase);
	@include funcBorderTopNone();
	@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_border_box_top.webp"), left, $top_posi);
}

@mixin mgFontBoldWithSize($size)
{
	@include funcFont($size);
	@include funcFontWeight(bold);
}

@mixin mgWrapTwoColumn( $pos_main, $pos_side)
{
	@include funcMarginAuto();
	@include funcWidth($dgBoxWidthBase);

	> div.main_column {
		@include funcWidth($dgBoxWidthMain);
		@include funcFloat($pos_main);
	}

	> div.side_column {
		@include funcWidth($dgBoxWidthSide);
		@include funcFloat($pos_side);

		> div.block_space {
			@include funcMarginTop( $dgBoxMarginBase);
		}
	}
}

@mixin mlTitleTopBgImg( $element, $bg_img)
{
	@include funcPadding(6, 0, 0, 15);
	@include funcOverflowHidden();
	@include funcBackgroundRepX($bg_img, left, top);

	> #{$element} {
		@include funcFont($dgFontSizeBase + 10);
		@include funcLineHeight(48);
		@include funcFontColor($dgFontColorInvert);

		> span.small_txt {
			@include funcFont($dgFontSizeBase + 10 - 4);
		}
	}
}

@mixin mgContentEdgeImgBoxTtl( $element, $bg_img)
{
	@include mgBlockSpace();

	> #{$element} {
		@include funcPadding(6, 0, 4, 40);
		@include funcMarginBottom( $dgBoxPaddingBase - 3);
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcLineHeight(33);
		@include funcBackgroundRepX($bg_img, left, top);
	}
}

.rating_star {
    position: relative;
    z-index: 0;
    display: inline-block;
    white-space: nowrap;
    color: #CCCCCC; /* グレーカラー 自由に設定化 */
}

.rating_star:before, .rating_star:after{
    content: '★★★★★';
}

.rating_star:after{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    color: #ffcf32; /* イエローカラー 自由に設定化 */
}
.rating_star[data-rate="5"]:after{ width: 100%; }
.rating_star[data-rate="4"]:after{ width: 80%; }
.rating_star[data-rate="3"]:after{ width: 60%; }
.rating_star[data-rate="2"]:after{ width: 40%; }
.rating_star[data-rate="1"]:after{ width: 20%; }
.rating_star[data-rate="0"]:after{ width: 0%; }
