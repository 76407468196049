
@mixin mgHover($color) {
	-webkit-tap-highlight-color: $color;
	tap-highlight-color: $color;
}

.mlMenu {
	z-index: 9999;
	width: 100%;
	position: relative;
	font-size: 13px;

	> .heading {
		font-weight: bold;
		color: #fff;
		font-size: 16px;
		padding: 12px 10px;
		padding-left: 40px;
		position: relative;
		background-image: url("/assets/smp/images/base/base_title_ic_menu.webp");
		background-position: 10px 50%;
		background-repeat: no-repeat;
		background-size: auto 16px;
		background-color: #65b820;

		> .close {
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;
			position: absolute;
			width: 30px;
			height: 30px;
			right: 10px;
			top: 50%;
			margin-top: -15px;
			background-image: url("/assets/smp/images/base/base_btn_close1.webp");
			background-position: right;
			background-repeat: no-repeat;
			background-size: 30px auto;
		}

		> .close:hover {
			background-image: url("/assets/smp/images/base/base_btn_close1_on.webp");
		}
	}

	> div{
		> .title {
			font-weight: bold;
			color: #fff;
			padding: 6px 10px 6px 35px;
			font-size: 14px;
			background-position: 10px 50%;
			background-repeat: no-repeat;
			background-size: auto 32px;
		}
	}

	> .rent {
		> .title {
			background-color: #65b820;
			background-image: url("/assets/smp/images/base/base_title_ic_desired.webp");
		}
	}

	> .sale {
		> .title {
			background-color: #65b820;
			background-image: url("/assets/smp/images/base/base_title_ic_sale.webp");
		}
	}

	> .salerent {
		> .title {
			background-color: #65b820;
			background-image: url("/assets/smp/images/base/base_title_ic_salerant.webp");
		}
	}

	> .shop {
		> .title {
			background-color: #65b820;
			background-image: url("/assets/smp/images/base/base_title_ic_search_shop.webp");
		}
	}

	> .contents {
		> .title {
			background-color: #65b820;
			background-image: url("/assets/smp/images/base/base_title_ic_contents.webp");
		}
	}

	> .site {
		> .title {
			background-color: #65b820;
			background-image: url("/assets/smp/images/base/base_title_ic_pitat_balloon.webp");
		}
	}

	> div {
		> ul {
			> li {
				border-bottom: 1px solid #ddd;

				> a {
					line-height: 1.2;
					padding: 12px 30px 12px 10px;
					display: block;
					background-color:#fff;
					background-image:url("/assets/smp/images/base/base_ic_link.webp") ;
					background-position: right;
					background-repeat: no-repeat;
					background-size: 18px 13px;
				}

				> form {
					> a {
						padding: 0;
						margin: 0;
						border: none;
						background: none;
						border-radius: 0;
						-webkit-appearance: none;
						appearance: none;
						font-size: 13px;
						width: 100%;
						text-align: left;
						line-height: 1.2;
						padding: 12px 30px 12px 10px;
						display: block;
						background-color: #fff;
						background-image: url("/assets/smp/images/base/base_ic_link.webp");
						background-position: right;
						background-repeat: no-repeat;
						background-size: 18px 13px;
						@include funcBoxSizingBorderBox();
					}
				}
			}

			 > li:last-child {
				border-bottom: none;
			}
		}
	}


	> .rent > ul > li > a {
		@include mgHover(#ffefe8);
	}

	> .sale > ul > li > a {
		@include mgHover(#dcf4f5);
	}

	> .salerent > ul > li > a {
		@include mgHover(#ffecd4);
	}

	> .shop > ul > li > a {
		@include mgHover(#dfffc3);
	}

	> .base > ul > li > a {
		@include mgHover(#dfffc3);
	}

}
.mlMenuContent {
	z-index: 0;
}