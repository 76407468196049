@mixin mlToolbar(){
		> div.button_area {
    		background-color: rgba(127,127,127,0.8);
    		padding: 10px 0 10px 10px;
    		height: 51px;

    		> ul {
    			width: 100%;
    			display: table;

    			> li {
    				display: table-cell;
    				text-align: center;
    				padding-right: 10px;
//    				border-width: 0;
//    				appearance: none;
//    				width: 100%;
//    				height: 45px;
//    				@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
//    				background-color: #ffa800;
//    				@include funcBorderRadius(5);
//    				@include mgHover(#ff8a00);
//    				vertical-align: middle;

    				> input[type="button"].favorite {
    					padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					background-image:url("/assets/smp/images/base/base_btn_add_favorite.webp");
    					background-size: auto 15px;
    					background-repeat: no-repeat;
    					background-position: 50% 50%;
    					margin-right: 0 10px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #398000);
    					background-color: #65b820;
    					@include funcBorderRadius(5);
    					@include mgHover(#53a90c);
    				}

    				> input[type="button"].del_favorite {
    					padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					background-image:url("/assets/smp/images/base/base_btn_del_favorite.webp");
    					background-size: auto 16px;
    					background-repeat: no-repeat;
    					background-position: 50% 50%;
    					margin-right: 0 10px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #398000);
    					background-color: #65b820;
    					@include funcBorderRadius(5);
    					@include mgHover($dgLinkHoverBkColorBase);
    				}
					> div.del_favorite {
						padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					margin-right: 0 10px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #398000);
    					background-color: #65b820;
    					@include funcBorderRadius(5);
    					@include mgHover($dgLinkHoverBkColorBase);

    					> input {
    						margin-top: 12px;
    						padding-left: 2px;
    						padding-right: 2px;
    					}
					}
					> div.checked_inquiry {
						padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
    					background-color: #ffa800;
    					@include funcBorderRadius(5);
    					@include mgHover(#ff8a00);

    					> input {
    						margin-top: 14px;
    						padding-left: 3px;
    					}
					}
    				> input[type="image"].checked_inquiry {
    					padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
    					background-color: #ffa800;
    					@include funcBorderRadius(5);
    					@include mgHover(#ff8a00);

    				}

					> div.inquiry {
						padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
    					background-color: #ffa800;
    					@include funcBorderRadius(5);
    					@include mgHover(#ff8a00);

    					> input {
    						margin-top: 14px;
    						padding-left: 5px;
    						padding-right: 2px;
    					}
					}
    				> input[type="image"].inquiry {
						padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
//   						background-image:url("/assets/smp/images/base/base_btn_inquiry.webp");
//   						background-size: auto 14px;
//   						background-repeat: no-repeat;
//    					background-position: 50% 50%;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
    					background-color: #ffa800;
    					@include funcBorderRadius(5);
    					@include mgHover(#ff8a00);
    				}

    				> div.search {
						padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
    					background-color: #ffa800;
    					@include funcBorderRadius(5);
    					@include mgHover(#ff8a00);

    					> input {
    						margin-top: 13px;
    					}
					}

    				> input[type="image"].search {
    					padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
//    					background-image:url("/assets/smp/images/base/base_btn_search_mark.webp");
//    					background-size: auto 16px;
//    					background-repeat: no-repeat;
//    					background-position: 23% 49%;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
    					background-color: #ffa800;
    					@include funcBorderRadius(5);
    					@include mgHover(#ff8a00);
						font-weight: bold;
    					color: #fff;
    					font-size: 16px;
    				}

    				> div.station {
						padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
    					background-color: #ffa800;
    					@include funcBorderRadius(5);
    					@include mgHover(#ff8a00);

    					> input {
    						margin-top: 14px;
    						padding-left: 3px;
    					}
					}

    				> input[type="button"].select_station {
    					padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;font-weight: bold;
    					width: 100%;
    					height: 45px;
    					background-image:url("/assets/smp/images/base/base_btn_search_mark.webp");
    					background-size: auto 16px;
    					background-repeat: no-repeat;
    					background-position: 37% 50%;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
    					background-color: #ffa800;
    					@include funcBorderRadius(5);
    					@include mgHover(#ff8a00);
    					color: #fff;
    					font-size: 16px;
    				}
					> div.narrows_data {
						padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, $dgShadowColorPitat);
    					background-color: #fff;
    					@include funcBorderRadius(5);
    					@include mgHover($dgLinkHoverBkColorBase);

    					> input {
    						margin-top: 15px;
    					}
					}

    				> input[type="image"].narrows_data {
    					padding: 0;
    					border-width: 0;
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					background-image:url("/assets/smp/images/base/base_btn_narrows_data_check.webp");
    					background-size: auto 16px;
    					background-repeat: no-repeat;
    					background-position: 20% 49%;
    					@include funcBoxShadowBottom($dgShadowSizeBase, $dgShadowColorPitat);
    					background-color: #fff;
    					@include funcBorderRadius(5);
    					@include mgHover($dgLinkHoverBkColorBase);
    					font-weight: bold;
    					color: #2b2b2b;
    					font-size: 15px;
    					margin-top: 13px;
    				}

    				> div.free_call {
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					margin-right: 0 10px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #162d5d);
    					background-color: #46a1d1;
    					@include funcBorderRadius(5);
    					padding: 0;
    					border-width: 0;
    					@include mgHover(#53a90c);

    					> input {
    						margin-top: 13px;
    						padding-left: 6px;
    						padding-right: 6px;
    					}
    				}

    				> input[type="image"].free_call {
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
//    					background-image:url("/assets/smp/images/base/base_btn_freecall.webp");
//    					background-size: auto 16px;
//    					background-repeat: no-repeat;
//    					background-position: 50% 50%;
    					margin-right: 0 10px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #162d5d);
    					background-color: #46a1d1;
    					@include funcBorderRadius(5);
    					padding: 0;
    					border-width: 0;
    					@include mgHover(#53a90c);
    				}

    				> div.call {
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					margin-right: 0 10px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #1b55ce);
    					background-color: #46a1d1;
    					@include funcBorderRadius(5);
    					padding: 0;
    					border-width: 0;
    					@include mgHover(#53a90c);

    					> input {
    						margin-top: 12px;
    					}
    				}

    				> input[type="button"].call {
    					-webkit-appearance: none;
    					appearance: none;
    					width: 100%;
    					height: 45px;
    					background-image:url("/assets/smp/images/base/base_btn_call_s.webp");
    					background-size: auto 17px;
    					background-repeat: no-repeat;
    					background-position: 52% 50%;
    					margin-right: 0 10px;
    					@include funcBoxShadowBottom($dgShadowSizeBase, #1b55ce);
    					background-color: #46a1d1;
    					@include funcBorderRadius(5);
   						padding: 0;
    					border-width: 0;
    					@include mgHover(#53a90c);
    				}

    				> input[type="button"].library_inquiry {
                        padding: 0;
						border-width: 0;
						-webkit-appearance: none;
						appearance: none;
						width: 100%;
						height: 45px;
						background-image:url("/assets/smp/images/base/base_btn_inquiry.webp");
						background-size: auto 14px;
						background-repeat: no-repeat;
						background-position: 50% 50%;
						@include funcBoxShadowBottom($dgShadowSizeBase, #b46805);
						background-color: #ffa800;
						@include funcBorderRadius(5);
						@include mgHover(#ff8a00);
					}
    			}

    			> li.col2 {
					width: 50%
    			}
    		}
    	}
}

.tmpFixedToolbar{
	@include mlToolbar();
}

.tmpToolbar{
	position: fixed;
	bottom: 0;
	width: 100%;

	@include mlToolbar();
}