.mlErrorPage {
	font-size: 13px;

	> .error {
		padding: 10px 10px 10px 80px;
		background-image: url("/assets/smp/images/base/base_ic_error.webp");
		background-position: 10px 50%;
		background-repeat: no-repeat;
		background-size: 60px auto;
		background-color: #eee;
		margin-bottom: 15px;

		> div {
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 6px;
		}
	}

	> div {

		> h2 {
			background: none;
			padding: 0;
			color: #333;
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 6px;
		}
	}

	> .home_btn {
		margin: 0 10px 20px;

		> a {
			margin: 0 auto;
			display: block;
			text-align: center;
			height: 45px;
			-webkit-box-shadow: 0 2px 0 0 #b46805;
			-moz-box-shadow: 0 2px 0 0 #b46805;
			box-shadow: 0 2px 0 0 #b46805;
			background-color: #ffa800;
			-moz-border-radius: 5px;
			-webkit-border-radius: 5px;
			border-radius: 5px;
			color: #fff;
			font-weight: bold;
			font-size: 14px;
			line-height: 45px;
			@include mgHover(#ff8a00);
		}
	}
}
