


body {
	@include funcFont($dgFontSizeBase);
	@include funcFontFamily($dgFontFamilyBase);
	@include funcFontColor($dgFontColorBase);
	@include funcLineHeight($dgFontLineheightBase);
	-webkit-text-size-adjust:100%;
}

textarea, input, a, img, select {
	@include funcOutline(none);
}


a {
	@include funcFontColor($dgLinkColorBase);
	@include funcTextDecoration(none);
}


input[type="text"], textarea {
	@include funcPaddingAll( 3);
}
input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }

input[type="checkbox"] {
	@include funcVerticalAlign(text-bottom);
}

.ui-menu-item {
	@include funcFont($dgFontSizeBase + 2);
	@include funcBkColor(#effbe5);

	> a:hover.ui-corner-all {
		@include funcFontColor($dgFontColorPitat);
	}
}
