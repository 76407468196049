@charset "utf-8";

@import "./_error_define.scss";

@import "../../base/_pitat_function.scss";
@import "../../base/_reset.scss";

@import "../base/_pitat_define.scss";
@import "../base/_pitat_reset.scss";
@import "../base/_pitat_base.scss";

@import "../base/component/_footer.scss";
@import "../base/component/_header.scss";
@import "../base/component/_menu.scss";
@import "../base/component/_toolbar.scss";
@import "../base/component/_breadcrumb.scss";
@import "../base/component/_contents.scss";

@import "./_error_base.scss"