



.mlFooter {
	@include funcBkColor(#65b820);

	> a {
		@include funcDisplay(block);
		@include funcWidthPcnt($dgBoxWidthWide);
		@include funcHeight(55-28-12);
		@include funcFont($dgFontSizeBase + 2);
		@include funcFontColor(#fff);
		@include funcPadding($dgBoxPaddingBase * 3, 0, $dgBoxPaddingBase, 0);
		@include funcTextAlign(center);
		@include funcBackgroundNoRep(url("/assets/smp/images/footer/footer_ic_pagetop.webp"), center, top);
		@include funcBkSizePcntHori(($dgImgSizeBase / 2));
		@include funcBkColor(#999);
		@include mgHover(#bbb);
	}

	> section {
		@include funcPaddingTopSideBtm(16, $dgBoxMarginBase, 16);
		@include funcOverflowHidden();

		> a {
			@include funcDisplay(block);
			@include funcWidthPcnt(($dgBoxWidthWide / 2));
			@include funcHeight(45);
			@include funcFloat(left);
			@include funcTextAlign(center);

			img {
				@include funcHeightPcnt($dgImgSizeBase);
			}
		}

		.viewpc {
			@include funcBkColor($dgBoxColorBase);
			@include mgHover($dgLinkHoverBkColorBase);
		}

		.viewsmp {
			@include funcBkColor(#666);
		}
	}

	> .attention {
		padding: 6px;
		display: block;
		font-size: 11px;
		color: #fff;
		text-align: center;
		line-height: 1.4;
	}

	> .about_pitat {
		padding: 8px 10px;
		display: block;
		font-size: 11px;
		text-align: left;
		line-height: 1.4;
	    background-color: #fff;
	}

	> nav {
		margin: 0 auto 16px;

		ul {
			@include funcTextAlign(center);

			li {
				@include funcDisplay(inline-block);
				@include funcPaddingVerHori(0, 16);
				@include funcBorderRight(1, solid, #fff);

				a {
					@include funcFontColor(#fff);
					@include funcFont($dgFontSizeBase - 2);
				}

				a:hover {
					@include funcTextDecoration(underline);
				}
			}

			li:first-child {
				@include funcPaddingLeft(0);
			}

			li:last-child {
				@include funcBorderRightNone();
				@include funcPaddingRight(0);
			}
		}
	}

	> div {
		@include funcBkColor(#65b820);
		@include funcPaddingVerHori(3, 0);
		@include funcFontColor(#fff);
		@include funcFont($dgFontSizeBase - 4);
		@include funcTextAlign(center);
	}

	> .footer_wrapper {
		background-color: #fff;
		@include funcFont($dgFontSizeBase + 3);
		text-align: left;

		> .footer_navi {
			> ul {
				> li {
					> dl {
						> dt {
							background-color: #65b820;
							padding: 8px 10px;
							font-weight: bold;
							border-bottom: 1px solid #ddd;
						}
						> div {
							> dd {
								padding: 3px 10px;
								> a {
									font-size: 13px;
									color: #0000EE;
	    							text-decoration: underline;
								}
							}
						}
						> .off {
							background-image: url(/assets/smp/images/base/base_btn_ic_plus.webp);
    						background-position: right;
    						background-size: 23px 54px;
    						background-repeat: no-repeat;
						}
						> .on {
							background-image: url(/assets/smp/images/base/base_btn_ic_minus.webp);
    						background-position: right;
    						background-size: 23px 54px;
    						background-repeat: no-repeat;
						}
					}
				}
			}
		}
	}
	> .footer_global_nav {
		margin: 0 auto 16px;
		margin-bottom: 15px;
			> ul {
				@include funcTextAlign(center);
				height: 73px;
				> li {
					@include funcWidthPcnt($dgBoxWidthWide / 2);
					@include funcFloat(left);
					border-right: 0px;
					padding: 0px;
					> div {
						@include funcBorderBottom(1, solid, #fff);
					    line-height: 35px;
					    background-color: #65b820;

						> a {
							@include funcFontColor(#fff);
							@include funcFont($dgFontSizeBase - 2);
							/* font-weight: bold; */
							font-size: 12px;
							display: block;
						}
					}
					> .left {
						@include funcBorderRight(1, solid, #fff);
					}
				}
			}
	}
}

@media screen and (max-width: 330px) {

	.mlFooter {

		> section {
			> a {
				@include funcHeight(45 - 5);

			}
		}
	}
}