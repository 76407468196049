

$dgFontColorBase: #333;
$dgFontColorHalf: #666;
$dgFontColorLight: #999;
$dgFontColorPitat: #390;
$dgFontColorInvert: #fff;
$dgFontColorHighlight: #f57900;
$dgFontColorAccent: #c60101;
$dgFontLinkColorBase: #333;

$dgFontSizeBase: 13;
$dgFontFamilyBase: 'ヒラギノ角ゴ ProN','Hiragino Kaku Gothic ProN','メイリオ',Meiryo,'ＭＳ Ｐゴシック','MS PGothic',sans-serif;
$dgFontLineheightBase: 20;



$dgColorRent: #65b820;

$dgColorSale: #65b820;

$dgColorSaleRent: #65b820;

$dgColorPitat: #65b820;




$dgBkColorRent: #effde3;

$dgBkColorSale: #effde3;

$dgBkColorSaleRent: #effde3;

$dgBkColorPitat: #effde3;


$dgLinkColorBase: #333;



$dgLinkHoverBkColorBase: #dfffc3;
$dgLinkActiveBkColorBase: #dfffc3;

$dgLinkHoverBkColorRent: #ffefe8;

$dgLinkHoverBkColorSale: #dcf4f5;

$dgLinkHoverBkColorSaleRent: #ffecd4;

$dgLinkHoverBkColorPitat: #dfffc3;


$dgImgSizeBase: 100;


$dgBoxColorBase: #fff;


$dgBoxWidthWide: 100;

$dgBoxWidthMaxWide: 420;

$dgBoxWidthBase: 960;

$dgBoxWidthMain: 715;
$dgBoxWidthSide: 225;

$dgBoxPaddingBase: 10;
$dgBoxMarginBase: 10;
$dgBoxPaddingLinkList: 20;
$dgBoxContentsSeparator: 20;


$dgBoxTopBorderSize: 6;


$dgBoxRadiusBase: 5;

$dgBoxRadiusSmall: 3;


$dgBorderColorBase: #ddd;
$dgBorderWidthBase: 1;

$dgBorderColorRent: #afd192;

$dgBorderColorRentLight: #afd192;

$dgBorderColorSale: #afd192;

$dgBorderColorSaleLight: #afd192;

$dgBorderColorSaleRent: #afd192;

$dgBorderColorSaleRentLight: #afd192;

$dgBorderColorPitat: #afd192;

$dgBorderColorPitatLight: #afd192;


$dgShadowSizeBase: 2;


$dgShadowColorBase: #bbb;
$dgShadowColorRent: #8bb864;
$dgShadowColorSale: #8bb864;
$dgShadowColorSaleRent: #8bb864;
$dgShadowColorPitat: #8bb864;


$dgBgImageCross: url("/assets/base/images/background/bg_gray_cross.webp");