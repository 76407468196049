

.mlContentsLink {
	@include mgTitleLeftIcon(div, url("/assets/smp/images/base/base_title_ic_contents.webp"));
	@include funcOverflowHidden();
	@include funcMarginBottom($dgBoxContentsSeparator);

	> ul {
		@include funcMarginRight($dgBoxMarginBase);

		> li {
			@include funcWidthPcnt(($dgBoxWidthWide / 4));
			@include funcFloat(left);
			@include mgImgLink();

			> a {
				@include funcDisplay(block);
				@include funcMargin($dgBoxMarginBase, 0, 0, $dgBoxMarginBase);

			}
		}
	}
}

@media screen and (max-width: 560px) {

	.mlContentsLink {

		> ul {

			> li {
				@include funcWidthPcnt(($dgBoxWidthWide / 2));
			}
		}
	}
}
