.smpBreadcrumb {
	margin-top: 25px;
	margin-bottom: 25px;
    border-top: 1px solid #ddd;

	.mgBreadcrumb {
		@include funcOverflowHidden();
		@include funcLineHeight(26);
		border-bottom: 1px solid #ddd;

		> ul {
			@include funcMarginAuto();
			width: max-content;

			> li {
				@include funcFontColor($dgFontColorLight);
				@include funcFloat(left);

				> span{
					padding: 0px 5px;
					margin-left: 15px;
				}
				> span.emphasis {
					@include funcFontColor($dgFontColorBase);
				}
			}
			> .BreadcrumbLink{
				@include funcFontColor($dgFontColorBase * 3);
				@include funcFloat(left);
				position: relative;
				flex-shrink: 0;

			}
			> .BreadcrumbLink:not(:first-child)::before{
				content:"";
				position: absolute;
				top: 50%;
				width: 0;
				height: 0;
				margin-top: -20px;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
				left: 1px;
   				border-left: 10px solid #d8d8d8;
			}
			> .BreadcrumbLink:not(:first-child)::after{
				content:"";
				position: absolute;
				top: 50%;
				width: 0;
				height: 0;
				margin-top: -20px;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
				left: 0;
				border-left: 10px solid #fff;
			}
		}
	}
}